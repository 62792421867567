<template>
  <ZgyxLayout>
    <assessmentForm></assessmentForm>
  </ZgyxLayout>
</template>

<script>
import ZgyxLayout from '@src/components/zgyxLayout/ZgyxLayout.vue';
import assessmentForm from './assessmentForm.vue';
export default {
  name: 'Dashboard',
  components: {
    ZgyxLayout,
    assessmentForm
  }
};
</script>

<style lang="less" scoped></style>

<template>
  <a-spin :spinning="spinning" :tip="tipText" size="large">
    <section class="layout">
      <div class="layout-content">
        <div class="left-wrap">
          <a-tree
            checkable
            :tree-data="treeList"
            :checkedKeys="checkedKeys"
            :autoExpandParent="true"
            :defaultExpandAll="true"
            v-if="treeList.length"
            @check="handleCheck"
            @select="handleSelect"
            :showIcon="true"
            :selectedKeys="selectedKeys"
          >
          </a-tree>
        </div>
        <div class="right-wrap" ref="right">
          {{ project.name }}（{{ project.regionType == 'community' ? '城镇社区' : '农村社区' }}）
          <div class="form-wrap" v-if="pane">
            <div class="form-content">
              <div class="form-title">{{ pane.name }}({{ pane.score }}分)</div>
              <div class="form-mark">一、指标说明</div>
              <div class="form-mark-value">
                {{ pane.note }}
              </div>
              <div class="form-mark">二、赋分标准</div>

              <div class="form-mark-value" v-for="(text, textIndex) in pane.markingNoteTexts" :key="textIndex">
                {{ textIndex + 1 }}.{{ text }}
              </div>
              <div class="form-mark">
                <span>三、评价方式</span>
                <a-radio-group
                  button-style="solid"
                  v-model:value="pane.paneActiveKey"
                  @change="
                    (e) => {
                      changeRadio(e, pane.paneActiveKey);
                    }
                  "
                >
                  <a-radio-button
                    :value="valIndex"
                    v-for="(val, valKey, valIndex) in pane.metricEvaluates"
                    :key="valIndex"
                  >
                    {{ transformPane(valKey) }}
                  </a-radio-button>
                </a-radio-group>
              </div>

              <template v-for="(metricEvaluateObj, metricEvaluateIndex) in getEvaluateList" :key="metricEvaluateIndex">
                <div class="evaluate-note">
                  <text v-if="metricEvaluateObj.required == 'yes'"> * </text>
                  {{ metricEvaluateIndex + 1 + '.' + metricEvaluateObj.note }}
                </div>
                <div class="self-wrap">
                  <template v-if="pane.showType == 'ordinary'">
                    <div class="self-title" v-if="metricEvaluateObj">佐证材料</div>
                    <a-button
                      type="primary"
                      @click="addLine(metricEvaluateObj.selfEvaluateEvidences)"
                      v-if="!isLook"
                      class="self-button"
                    >
                      新增
                    </a-button>
                  </template>

                  <div class="self-content">
                    <div
                      style="width: 100%; margin-bottom: 24px"
                      v-for="(tt, ttIndex) in selfEvaluateEvidencesEmptyAdd(metricEvaluateObj.selfEvaluateEvidences)"
                      :key="ttIndex"
                    >
                      <a-textarea
                        v-model:value="tt.evidence"
                        :placeholder="
                          pane.showType == 'ordinary' ? '请输入佐证材料' : `请简述${metricEvaluateObj.note}`
                        "
                        :rows="6"
                        style="margin-bottom: 12px; font-size: 16px"
                        v-if="!isLook"
                      />
                      <div v-if="isLook" style="font-size: 16px">
                        {{ tt.evidence }}
                      </div>
                      <div style="clear: both; width: 100%; height: auto; overflow: hidden">
                        <div style="width: 400px; float: left">
                          <a-upload
                            :file-list="tt.fileList"
                            :multiple="true"
                            :customRequest="(value) => uploadFiles(value, tt)"
                            :disabled="isLook"
                            :showUploadList="{
                              showPreviewIcon: false,
                              showRemoveIcon: false,
                              showDownloadIcon: false
                            }"
                            :before-upload="onBeforeUpload"
                          >
                            <a-button v-if="tt.fileList.length < fileLimit && !isLook"> 上传附件 </a-button>
                          </a-upload>
                          <div class="file-wrap">
                            <div class="file-item" v-for="(file, fileIndex) in tt.fileList" :key="fileIndex">
                              <span class="file-index"> {{ fileIndex + 1 }}. </span>
                              <template v-if="isImage(file)">
                                <img class="file-image" :src="file.url" @click="goPreview(file)" />
                              </template>
                              <template v-else>
                                <span class="file-name" @click="goPreview(file)">
                                  {{ file.name }}
                                </span>
                              </template>

                              <span class="file-down" @click="goPreview(file)">在线预览</span>

                              <span class="file-down" @click="handleDownload(file)">立即下载</span>
                              <span v-if="!isLook" class="file-delete" @click="deleteFile(tt.fileList, fileIndex)">
                                <DeleteOutlined :style="{ fontSize: '18px' }" />
                              </span>
                            </div>
                          </div>
                        </div>

                        <template v-if="pane.showType == 'ordinary'">
                          <a-popconfirm
                            placement="left"
                            ok-text="确定"
                            cancel-text="取消"
                            @confirm="deleteLine(metricEvaluateObj.selfEvaluateEvidences, ttIndex)"
                          >
                            <template #title> 你确定要删除该佐证材料？ </template>
                            <a-button type="primary" danger style="float: right" v-if="!isLook && ttIndex > 0"
                              >删除</a-button
                            >
                          </a-popconfirm>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="layout-footer">
        <div class="layout-footer-flex">
          <div class="footer-left">{{ pane.showIndex }}.{{ pane.name }}</div>
          <div class="footer-middle">
            <div class="current-score-self">
              <span class="label">自评得分：</span>
              <a-input-number
                class="number"
                v-model:value="pane.selfEvaluateScore"
                :min="0"
                :max="pane.score"
                @blur="calculationData"
                v-if="!isLook"
              />
              <span class="number-look" v-else>{{ pane.selfEvaluateScore }}</span>
              <span class="mark">分</span>
            </div>
            <div class="current-score-all">
              <span class="label">自评总得分：</span
              ><span class="number">{{ Number(Number(selfEvaluateScore).toFixed(2)) }}</span>
              <span class="mark">分</span>
            </div>
          </div>
          <div class="footer-right">
            <template v-if="!isLook">
              <div class="right-top">
                <div class="button-wrap">
                  <a-button @click="prevForm" block>上一项</a-button>
                </div>
                <div class="button-wrap">
                  <a-button type="primary" v-if="!isLook" @click="handleApiPostTemporary(true)" block>保存</a-button>
                </div>
                <div class="button-wrap">
                  <a-button @click="nextForm" block>下一项</a-button>
                </div>
              </div>
              <div class="right-bottom">
                <div class="button-wrap">
                  <a-button @click="isLook = true" block>立即预览</a-button>
                </div>

                <div class="button-wrap">
                  <a-button type="primary" @click="handleApiPostSave" block>确认提交</a-button>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="right-top">
                <div class="button-wrap">
                  <a-button @click="prevForm" block>上一项</a-button>
                </div>
                <div class="button-wrap">
                  <a-button @click="nextForm" block>下一项</a-button>
                </div>
              </div>

              <div class="right-bottom" v-if="pageType != 'look'">
                <div class="button-wrap">
                  <a-button block type="primary" @click="isLook = false" :disabled="pageType == 'look'">返回</a-button>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </section>

    <previewFile :file="activeFile" v-if="visibleModalFile" @onCancel="onCancelModalFile"></previewFile>
  </a-spin>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import { message } from 'ant-design-vue';
import { CheckCircleFilled, DeleteOutlined, LoadingOutlined } from '@ant-design/icons-vue';
import { apiGetProjectDetail, apiGetProjectDetailByLook, apiPostTemporary, apiPostSave } from '@src/apis/community';
import { previewPdfApi } from '@src/apis/index';

import { regExpCheckEmail, regExpCheckPhone } from '@src/configs';
import { downloadFile2 } from '@src/utils';

import { previewFile } from '@src/components';

export default {
  name: '',
  components: { CheckCircleFilled, DeleteOutlined, LoadingOutlined, previewFile },
  data() {
    return {
      treeList: [],
      realmNoIndex: 0,
      metricNoIndex: 0,
      spinning: false,
      isLook: false,
      projectResultId: '', //项目结果id
      projectId: '',
      project: {},
      projectResult: {},
      realms: [], //项目tab数组
      selfEvaluateScore: 0,
      requestFlag: false,
      pageType: '',
      activeFile: {},
      visibleModalFile: false,
      //
      tipText: '加载中......',
      previewFlag: false,
      selectedKeys: [],
      fileLimit: 6 //佐证每个的个数限制
    };
  },
  computed: {
    ...mapGetters(['userinfo', 'dictionaries', 'token']),
    evaluateType() {
      return this.dictionaries.evaluateType || [];
    },
    // 邮箱
    regExpCheckEmail() {
      return regExpCheckEmail;
    },
    // 手机号格式
    regExpCheckPhone() {
      return regExpCheckPhone;
    },
    checkedKeys() {
      const _this = this;
      let resList = [];

      let metricsList = [];

      _this.realms.forEach((item) => {
        metricsList = metricsList.concat(item.metrics);
      });
      metricsList.forEach((item) => {
        if (item.complete) {
          resList.push(item.metricNo);
        }
      });

      return resList;
    },
    pane() {
      let paneText = this.realms[this.realmNoIndex]?.metrics[this.metricNoIndex] || {};
      return paneText;
    },
    getEvaluateList() {
      let res = [];
      if (this.pane && this.pane.metricEvaluates) {
        let activeKey = this.pane.paneActiveKey || 0;
        let keys = Object.keys(this.pane.metricEvaluates) || [];
        let key = keys[activeKey];
        res = this.pane.metricEvaluates[key];
      }

      return res;
    },
    metricEvaluatesKeys() {
      let keys = [];
      if (this.pane && this.pane.metricEvaluates) {
        keys = Object.keys(this.pane.metricEvaluates) || [];
      }

      return keys;
    }
  },
  created() {
    const _this = this;

    _this.pageType = _this.$route.query.type || '';
    if (_this.pageType == 'look') {
      // 只能查看
      _this.isLook = true;
      _this.projectResultId = _this.$route.query.id;
    } else {
      _this.projectId = _this.$route.query.id;
      //获取暂存
    }

    _this.$nextTick(async () => {
      await _this.handleGetDetails();
    });
  },
  mounted() {},
  methods: {
    ...mapActions(['setActiveMenu', 'setActiveMenuReplace']),
    /******************上传文件方法******************/
    //佐证附件上传
    async uploadFiles(info, item) {
      //初始化文件信息
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: 'uploading',
        response: '',
        url: ''
      };
      //放入上传列表中，以便于显示上传进度
      //开始真正上传
      //上传接口
      let uploadApiUrl = '/cmsapi/system/upload/tenant/upload/try-to-pdf';
      //调用公共上传方法
      this.tipText = '上传中...';
      this.spinning = true;
      const res = await this.uploadFilesToServer(uploadApiUrl, 'file', info.file);
      console.log(res);
      this.spinning = false;
      //根据服务端返回的结果判断成功与否，设置文件条目的状态
      if (res.code === '00000') {
        fileInfo.status = 'done';
        fileInfo.uid = res.data.id;
        fileInfo.response = res.data.message;
        fileInfo.url = res.data.url;
        this.$message.success('上传成功！');
        item.fileList.push(fileInfo);
      } else {
        fileInfo.status = 'error';
        fileInfo.response = res.data.message;
        // this.$message.error('上传失败！');
      }
    },
    /******************上传文件公共方法******************/
    uploadFilesToServer(uploadApiUrl, fileName, files) {
      const _this = this;
      let formData = new FormData();
      formData.append(fileName, files);
      //添加请求头
      const headers = {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${_this.token}`
      };
      //配置头
      const request = axios.create({
        headers: headers
      });
      //开始上传
      return request
        .post(uploadApiUrl, formData)
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    //上传前
    onBeforeUpload(file) {
      try {
        return new Promise((resolve, reject) => {
          const isLt50M = file.size / 1024 / 1024 < 100;
          if (!isLt50M) {
            message.warning('上传文件大小不能超过100MB！');
            return reject(false);
          }
          return resolve(true);
        });
      } catch (error) {
        console.error(error);
      }
    },
    //预览取消
    onCancelModalFile() {
      try {
        const _this = this;
        _this.visibleModalFile = false;
        _this.activeFile = {};
      } catch (error) {
        console.error(error);
      }
    },
    isImage(file) {
      const _this = this;
      let name = file.name;
      let splitList = name.split('.');
      let type = splitList[splitList.length - 1].toLowerCase();
      let imgType = ['gif', 'jpeg', 'jpg', 'png'];
      if (imgType.includes(type)) {
        // 图片，直接跳转打开
        return true;
      } else {
        return false;
      }
    },
    //预览
    async goPreview(file) {
      const _this = this;
      _this.tipText = '加载中...';
      _this.spinning = true;
      let name = file.name;
      let splitList = name.split('.');
      let type = splitList[splitList.length - 1].toLowerCase();

      let imgType = ['gif', 'jpeg', 'jpg', 'png'];
      let fileTypeList = ['docx', 'doc', 'xls', 'xlsx', 'ppt', 'pptx'];
      let videoType = ['mp3', 'mp4'];
      if (fileTypeList.includes(type)) {
        console.log('文件');
        if (_this.previewFlag) {
          _this.spinning = false;
          return false;
        }
        _this.previewFlag = true;
        const { uid } = file;
        const res = await previewPdfApi({ uuid: uid });
        if (res && res.code === '00000') {
          _this.activeFile = {
            pdfSrc: res.data.url
          };
          _this.visibleModalFile = true;
        }

        setTimeout(() => {
          _this.previewFlag = false;
        }, 1000);
      } else if (type == 'pdf') {
        _this.activeFile = {
          pdfSrc: file.url
        };
        _this.visibleModalFile = true;
      } else if (imgType.includes(type)) {
        // 图片，直接跳转打开
        window.open(file.url);
      } else if (videoType.includes(type)) {
        window.open(file.url);
      } else {
        message.error('预览暂不支持当前格式的文件');
      }
      _this.spinning = false;
    },
    //切换评价方式
    changeRadio(e, paneActiveKey) {
      paneActiveKey = e.target.value;
    },
    //-----1.1
    handleCheck() {
      return null;
    },
    //树选择
    handleSelect(key, e) {
      this.selectedKeys = key;
      let eventKey = e.node.eventKey;
      if (!this.isLook) {
        this.handleApiPostTemporary(false);
      }

      this.treeList.forEach((item, index) => {
        if (item.key == eventKey) {
          this.realmNoIndex = index;
          this.metricNoIndex = 0;
          this.$refs.right.scrollTop = 0;
        } else {
          item.children.forEach((child, childIndex) => {
            if (child.key == eventKey) {
              this.realmNoIndex = index;
              this.metricNoIndex = childIndex;
              this.$refs.right.scrollTop = 0;
            }
          });
        }
      });
    },
    //生成树
    createTreeData(list) {
      let treeList = list.map((item, index) => {
        return {
          title: this.toChinesNum(index + 1) + '、' + item.name + '(' + item.score + '分)',
          key: item.realmNo,
          style: {
            fontSize: '18px',
            fontFamily: 'PingFangSC-Semibold, PingFang SC',
            fontWeight: 'bold',
            color: '#333333',
            lineHeight: '25px'
          },
          checkable: false,
          selectable: false,
          children: item.metrics.map((child, childIndex) => {
            let checkableValue = true;
            if (child.selfMaxScore != undefined && child.selfMaxScore == 0) {
              checkableValue = false;
            }
            return {
              title:
                (child.showIndex ? child.showIndex : childIndex + 1) + '.' + child.name + '(' + child.score + '分)',
              key: child.metricNo,
              style: {
                fontSize: '16px',
                fontFamily: 'PingFangSC-Semibold, PingFang SC',
                fontWeight: 'normal',
                color: '#333333',
                lineHeight: '22px',
                marginLeft: checkableValue ? 0 : '21px'
              },
              checkable: checkableValue
            };
          })
        };
      });
      this.treeList = treeList;
      this.selectedKeys = [this.realms[this.realmNoIndex]?.metrics[this.metricNoIndex].metricNo];
    },
    //删除文件
    deleteFile(list, index) {
      list.splice(index, 1);
    },
    //-----1.1 下载
    handleDownload(e) {
      console.log(e);
      this.tipText = '下载中...';
      this.spinning = true;
      downloadFile2(
        {
          url: e.url,
          fileName: e.name
        },
        () => {
          this.spinning = false;
        }
      );
    },
    //转中文
    toChinesNum(num) {
      let changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
      let unit = ['', '十', '百', '千', '万'];
      num = parseInt(num);
      let getWan = (temp) => {
        let strArr = temp.toString().split('').reverse();
        let newNum = '';
        let newArr = [];
        strArr.forEach((item, index) => {
          newArr.unshift(item === '0' ? changeNum[item] : changeNum[item] + unit[index]);
        });
        let numArr = [];
        newArr.forEach((m, n) => {
          if (m !== '零') numArr.push(n);
        });
        if (newArr.length > 1) {
          newArr.forEach((m, n) => {
            if (newArr[newArr.length - 1] === '零') {
              if (n <= numArr[numArr.length - 1]) {
                newNum += m;
              }
            } else {
              newNum += m;
            }
          });
        } else {
          newNum = newArr[0];
        }
        return newNum;
      };
      let overWan = Math.floor(num / 10000);
      let noWan = num % 10000;
      if (noWan.toString().length < 4) {
        noWan = '0' + noWan;
      }
      return overWan ? getWan(overWan) + '万' + getWan(noWan) : getWan(num);
    },
    //佐证材料默认增加一个
    selfEvaluateEvidencesEmptyAdd(list) {
      if (list.length <= 0 || !list) {
        list.push({
          evidence: '',
          attachment: [],
          fileList: []
        });
      }
      return list;
    },

    //执行检查是否完全 + 分数和进度条
    calculationData() {
      const _this = this;
      _this.chenckQuota();
      _this.calculationProgressAndScore();
    },
    //计算分数
    calculationProgressAndScore() {
      try {
        const _this = this;
        let metricsList = [];
        _this.realms.forEach((item) => {
          metricsList = metricsList.concat(item.metrics);
        });
        let i = 0;
        let score = 0;
        metricsList.forEach((item) => {
          if (item.complete) {
            i++;
          }
          score += Number(item.selfEvaluateScore || 0);
        });
        _this.selfEvaluateScore = Number(Number(score).toFixed(2));
      } catch (error) {
        console.log(error);
      }
    },
    //点击计算是否完成
    chenckQuota() {
      // 判断是否填完
      try {
        const _this = this;
        _this.realms.forEach((currentRealm) => {
          currentRealm.metrics.forEach((currentZb) => {
            let complete = true;
            if (currentZb.selfMaxScore != undefined && currentZb.selfMaxScore == 0) {
              currentZb.complete = complete;
            } else {
              if (!currentZb.selfEvaluateScore && currentZb.selfEvaluateScore !== 0) {
                //没填自评分
                complete = false; //没完整
              }
              //评价对象
              let metricEvaluatesList = [];
              Object.keys(currentZb.metricEvaluates).forEach((key) => {
                metricEvaluatesList = metricEvaluatesList.concat(currentZb.metricEvaluates[key]);
              });

              metricEvaluatesList.forEach((item) => {
                // && item.type != 'scene'
                if (item.required == 'yes') {
                  //说明是必填项

                  if (!item.selfEvaluateEvidences || item.selfEvaluateEvidences.length <= 0) {
                    complete = false; //没完整
                  } else {
                    //加了列表，没有填完

                    item.selfEvaluateEvidences.forEach((self) => {
                      if (!self.evidence && (!self.fileList || (self.fileList && self.fileList.length <= 0))) {
                        complete = false; //没完整
                      }
                    });
                  }
                }
              });
              currentZb.complete = complete;
            }
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    chenckQuotaComplete() {
      //保存前校验 有提示
      try {
        const _this = this;
        let list = _this.realms;
        let allComplete = true;
        for (let i = 0; i < list.length; i++) {
          let currentRealm = list[i];
          for (let y = 0; y < currentRealm.metrics.length; y++) {
            let currentZb = currentRealm.metrics[y];
            let complete = true;

            if (!(currentZb.selfMaxScore != undefined && currentZb.selfMaxScore == 0)) {
              if (!currentZb.selfEvaluateScore && currentZb.selfEvaluateScore !== 0) {
                //没填自评分
                complete = false; //没完整
                message.error(currentRealm.name + '/' + currentZb.name + '/自评分未填写');
                return false;
              }
              //评价对象
              let metricEvaluatesList = [];
              Object.keys(currentZb.metricEvaluates).forEach((key) => {
                metricEvaluatesList = metricEvaluatesList.concat(currentZb.metricEvaluates[key]);
              });

              for (let z = 0; z < metricEvaluatesList.length; z++) {
                let item = metricEvaluatesList[z];
                console.log(item);
                // && item.type != 'scene'
                if (item.required == 'yes') {
                  //说明是必填项

                  if (!item.selfEvaluateEvidences || item.selfEvaluateEvidences.length <= 0) {
                    complete = false; //没完整
                    let tipText = currentZb.showType == 'ordinary' ? '佐证材料未填写' : '未填写';
                    message.error(currentRealm.name + '/' + currentZb.name + '/' + item.note + tipText);
                    break;
                  } else {
                    //加了列表，没有填完
                    let xx = true;
                    for (let u = 0; u < item.selfEvaluateEvidences.length; u++) {
                      let self = item.selfEvaluateEvidences[u];
                      if (!self.evidence && (!self.fileList || (self.fileList && self.fileList.length <= 0))) {
                        complete = false; //没完整
                        let tipText2 =
                          currentZb.showType == 'ordinary'
                            ? '佐证材料文本未填写或者附件未上传'
                            : '文本未填写或者附件未上传';
                        message.error(currentRealm.name + '/' + currentZb.name + '/' + item.note + tipText2);
                        xx = false;
                        break;
                      }
                    }
                    if (!xx) {
                      break;
                    }
                  }
                }
              }
              if (!complete) {
                allComplete = allComplete && complete;
                return false;
              }
            }
          }
        }

        return allComplete;
      } catch (error) {
        console.log(error);
      }
    },
    //设置获取来的数据到页面
    setProjectData() {
      try {
        const _this = this;
        let { metricResults } = _this.projectResult;

        _this.realms.forEach((item, index) => {
          //域
          item.metrics.forEach((metrics, metricsIndex) => {
            //指标
            let metricNo = metrics.metricNo; // 指标编号

            Object.keys(metricResults).forEach((key) => {
              //结果指标对象遍历
              if (key == metricNo) {
                // 记录的指标和数据对应
                metrics.selfEvaluateScore = metricResults[key].selfEvaluateScore;
                let evaluationMethodObject = metrics.metricEvaluates; //原始的评价方式
                Object.keys(evaluationMethodObject).forEach((metricEvaluatesKey) => {
                  //
                  evaluationMethodObject[metricEvaluatesKey].forEach((evaluationMethodObjectItem) => {
                    //每个评价方式
                    let metricEvaluateResultsSub = metricResults[key].metricEvaluateResults; // 结果评价方式对象

                    Object.keys(metricEvaluateResultsSub).forEach((metricEvaluateResultsSubKey) => {
                      if (metricEvaluateResultsSubKey == evaluationMethodObjectItem.metricEvaluateNo) {
                        let selfEvaluateEvidences =
                          metricEvaluateResultsSub[metricEvaluateResultsSubKey].selfEvaluateEvidences;

                        selfEvaluateEvidences = selfEvaluateEvidences.map((selfEvaluateEvidencesItem) => {
                          //评价方式佐证材料或者亮点 赋值
                          let fileList = selfEvaluateEvidencesItem.attachmentFiles;

                          fileList = fileList.map((file) => {
                            return {
                              uid: file.id,
                              name: file.fileName,
                              status: 'done',
                              response: 'Server Error 500',
                              url: file.url
                            };
                          });

                          return {
                            evidence: selfEvaluateEvidencesItem.evidence,
                            attachment: selfEvaluateEvidencesItem.attachment.split(','),
                            fileList: fileList
                          };
                        });

                        evaluationMethodObjectItem.selfEvaluateEvidences = selfEvaluateEvidences;
                      }
                    });
                  });
                });
              }
            });
          });
        });

        _this.calculationData();
      } catch (error) {
        console.log(error);
      }
    },
    //拼接id
    getFileIds(list) {
      let ids = [];
      list.forEach((item) => {
        if (item && item.response && item.response.data) {
          ids.push(item.response.data.id);
        } else if (item && item.response && item.uid) {
          ids.push(item.uid);
        }
      });
      return ids.join();
    },
    /**
     * 保存
     * @param {*}
     */
    async handleApiPostSave() {
      try {
        const _this = this;
        _this.calculationData();
        if (!_this.chenckQuotaComplete()) {
          return false;
        }

        if (_this.requestFlag) {
          return false;
        }
        _this.requestFlag = true;
        let metricResults = [];
        _this.realms.forEach((item) => {
          metricResults = metricResults.concat(item.metrics);
        });
        let copyMetricResults = JSON.parse(JSON.stringify(metricResults));

        copyMetricResults = copyMetricResults.map((item) => {
          let metricEvaluateResults = [];

          for (let key in item.metricEvaluates) {
            for (let i = 0; i < item.metricEvaluates[key].length; i++) {
              let metricEvaluatesObj = item.metricEvaluates[key][i];
              let evaluateArray = metricEvaluatesObj.selfEvaluateEvidences;
              for (let y = 0; y < evaluateArray.length; y++) {
                let fileList = evaluateArray[y].fileList;
                if (fileList.length > 0) {
                  evaluateArray[y].attachment = _this.getFileIds(fileList);
                } else {
                  evaluateArray[y].attachment = '';
                }
                delete evaluateArray[y].fileList;
              }

              metricEvaluateResults.push({
                selfEvaluateEvidences: evaluateArray,
                metricEvaluateNo: metricEvaluatesObj.metricEvaluateNo
              });
            }
          }
          let itemSelfEvaluateScore = item.selfEvaluateScore;
          return {
            selfEvaluateScore: itemSelfEvaluateScore,
            metricNo: item.metricNo,
            metricEvaluateResults: metricEvaluateResults
          };
        });
        let sendData = {
          projectId: _this.projectId,
          metricResults: copyMetricResults
        };
        console.log(sendData);

        setTimeout(() => {
          _this.requestFlag = false;
        }, 2000);
        const { data, code } = await apiPostSave({ param: JSON.stringify(sendData) });
        _this.requestFlag = false;
        if (code === '00000') {
          message.success('保存成功');
          _this.projectResultId = data;
          _this.calculationData();
          _this.isLook = true;
          window.history.go(-1);
        } else {
          // message.error('保存失败');
        }
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 暂存
     * @param {*}
     */
    async handleApiPostTemporary(showToastFlag) {
      try {
        const _this = this;
        if (_this.requestFlag) {
          return false;
        }
        _this.requestFlag = true;
        setTimeout(() => {
          _this.requestFlag = false;
        }, 2000);

        let copyRealms = JSON.parse(JSON.stringify(_this.realms));
        copyRealms.forEach((item, index) => {
          //域
          item.metrics.forEach((metrics, metricsIndex) => {
            let evaluationMethodObject = metrics.metricEvaluates; //原始的评价方式

            Object.keys(evaluationMethodObject).forEach((metricEvaluatesKey) => {
              //
              evaluationMethodObject[metricEvaluatesKey].forEach((evaluationMethodObjectItem) => {
                let selfEvaluateEvidencesList = evaluationMethodObjectItem.selfEvaluateEvidences;
                selfEvaluateEvidencesList.forEach((selfEvaluateEvidencesItem) => {
                  selfEvaluateEvidencesItem.fileList = selfEvaluateEvidencesItem.fileList.map((fileItem) => {
                    return Object.assign(
                      { ...fileItem },
                      {
                        url: `@@${fileItem.uid}@@`
                      }
                    );
                  });
                });
              });
            });
          });
        });

        const { code } = await apiPostTemporary({
          projectId: _this.projectId,
          param: JSON.stringify(copyRealms)
        });
        _this.requestFlag = false;
        if (code === '00000') {
          if (showToastFlag) {
            message.success('暂存成功');
          }

          _this.calculationData();
        } else {
          // message.error('暂存失败');
        }
      } catch (error) {
        console.log(error);
      }
    },
    //转化标 文字
    transformPane(key) {
      let text = '未知方式';
      this.evaluateType.forEach((item) => {
        if (key == item.value) {
          text = item.name;
        }
      });
      return text;
    },
    /*
     *获取详情
     */
    async handleGetDetails() {
      try {
        const _this = this;
        let api = '';
        let param = '';
        if (_this.pageType == 'look') {
          param = _this.projectResultId;
          api = apiGetProjectDetailByLook;
        } else {
          param = _this.projectId;
          api = apiGetProjectDetail;
        }
        _this.spinning = true;
        const { code, data } = await api(param);
        _this.spinning = false;
        if (code === '00000' && data) {
          const { project, projectResult, temporaryJson } = data;
          const { realms } = project;
          _this.project = project;
          _this.projectResult = projectResult;
          _this.realms = realms.map((item) => {
            item.metrics = item.metrics.map((sub) => {
              for (let key in sub.metricEvaluates) {
                for (let i = 0; i < sub.metricEvaluates[key].length; i++) {
                  sub.metricEvaluates[key][i].selfEvaluateEvidences = [];
                }
              }
              return {
                paneActiveKey: 0,
                ...sub
              };
            });
            return {
              subActiveKey: 0,
              ...item
            };
          });
          if (temporaryJson) {
            let defaultRealms = JSON.parse(JSON.stringify(_this.realms));
            console.log('执行了暂存');
            //有暂存取暂存,没有暂存不执行
            // _this.realms = JSON.parse(temporaryJson);
            try {
              let tempRealms = JSON.parse(temporaryJson);
              _this.realms.forEach((original, originalIndex) => {
                let tempMetrics = tempRealms[originalIndex].metrics;
                original.metrics.forEach((originalMetric, originalMetricIndex) => {
                  let tempMetric = tempMetrics[originalMetricIndex];
                  originalMetric.complete = tempMetric.complete;
                  originalMetric.selfEvaluateScore = tempMetric.selfEvaluateScore;

                  let tempMetricEvaluates = tempMetric.metricEvaluates;

                  Object.keys(originalMetric.metricEvaluates).forEach((key) => {
                    let tempMetricEvaluate = tempMetricEvaluates[key]; //
                    originalMetric.metricEvaluates[key].forEach((selfEvaluateEvidence, selfEvaluateEvidenceIndex) => {
                      selfEvaluateEvidence.selfEvaluateEvidences =
                        tempMetricEvaluate[selfEvaluateEvidenceIndex].selfEvaluateEvidences;
                    });
                  });
                });
              });
              // throw new Error('error');
            } catch (error) {
              console.error('暂存数据读取异常', error);
              _this.realms = defaultRealms;
            }
            _this.calculationData(); //计算每个标相加的总得分
          } else {
            console.log('执行了保存完了的查看功能 或者 只填完了指标');

            _this.selfEvaluateScore = projectResult.selfEvaluateScore;
            if (projectResult.metricResults) {
              // 暂存的数据
              _this.setProjectData();
            }

            if (projectResult.projectResultId) {
              _this.projectResultId = projectResult.projectResultId;
            }
          }

          _this.createTreeData(realms);
        }
      } catch (error) {
        console.error(error);
      }
    },

    //减佐证
    deleteLine(list, index) {
      if (list.length <= 1) {
        return false;
      }
      list.splice(index, 1);
    },
    //增佐证
    addLine(list) {
      if (list.length >= this.fileLimit) {
        return false;
      }
      list.push({
        evidence: '',
        attachment: [],
        fileList: []
      });
    },
    //计算文字
    transformTab(pane, paneIndex) {
      let isShow = pane.complete;
      let dom = '';
      if (isShow) {
        dom = (
          <div>
            <CheckCircleFilled style="color:#53bd1f" />
            <span>
              {paneIndex + 1}.{pane.name}
            </span>
            <span style="display:inline-block;">( {pane.score}分</span>)
          </div>
        );
      } else {
        dom = (
          <div>
            <span>
              {paneIndex + 1}.{pane.name}
            </span>
            <span style="display:inline-block;">( {pane.score}分)</span>
          </div>
        );
      }
      return dom;
    },
    //上页切换
    async prevForm() {
      if (this.metricNoIndex <= 0) {
        if (this.realmNoIndex > 0) {
          this.realmNoIndex--;
          let item = this.realms[this.realmNoIndex];
          this.metricNoIndex = item.metrics.length - 1;
          if (!this.isLook) {
            this.handleApiPostTemporary(false);
          }
        }
      } else {
        this.metricNoIndex--;
        if (!this.isLook) {
          this.handleApiPostTemporary(false);
        }
      }
      this.$refs.right.scrollTop = 0;
      this.selectedKeys = [this.realms[this.realmNoIndex]?.metrics[this.metricNoIndex].metricNo];
    },
    //下页切换
    async nextForm() {
      let item = this.realms[this.realmNoIndex];
      if (this.metricNoIndex >= item.metrics.length - 1) {
        if (this.realmNoIndex < this.realms.length - 1) {
          this.realmNoIndex++;
          this.metricNoIndex = 0;
          if (!this.isLook) {
            this.handleApiPostTemporary(false);
          }
        }
      } else {
        this.metricNoIndex++;
        if (!this.isLook) {
          this.handleApiPostTemporary(false);
        }
      }
      this.$refs.right.scrollTop = 0;
      this.selectedKeys = [this.realms[this.realmNoIndex]?.metrics[this.metricNoIndex].metricNo];
    }
  }
};
</script>
<style lang="less" scoped>
@import './assessmentForm.less';
</style>

<style>
.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: rgb(83, 189, 31);
  border-color: rgb(83, 189, 31);
  border: none;
  border-radius: 50%;
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner:hover {
  border: none;
}
.ant-tree-checkbox-checked::after {
  border: none;
}
.ant-tree-checkbox-inner {
  border-radius: 50%;
}
/* .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background: transparent !important;
} */
.ant-upload-list-item-card-actions {
  opacity: 1;
  font-size: 16px;
}
.ant-upload-list-item-name {
  font-size: 16px;
  line-height: 32px;
}
.ant-upload-list-item-info {
  line-height: 32px;
  height: 32px;
}
.ant-upload-list-item {
  height: 32px;
}
.ant-upload-list-item-info .anticon-loading,
.ant-upload-list-item-info .anticon-paper-clip {
  font-size: 16px;
  line-height: 30px;
  top: 4px;
}

.self-content .ant-upload-list {
  display: none;
}
</style>
